const BASE_API_URL_LOCAL = 'http://localhost:1337'
const BASE_API_URL = 'https://tool-syslog.herokuapp.com'

export const COLOR_WHITE = '#ffffff'
export const COLOR_GRAY = '#ebedef'
export const COLOR_BLUE = '#321fdb'
export const COLOR_GREEN = '#2eb85c'
export const COLOR_LIGHT_BLUE = '#3399ff'

const COLORS = [COLOR_BLUE, '#e55353', '#f9b115', COLOR_LIGHT_BLUE, '#9da5b1', COLOR_GREEN]

export const BASIC_AUTH_KEY = 'basic_auth_key_go_cloudwatch'

export const getColor = (i) => {
  return COLORS[i % COLORS.length]
}

export const getBasicAuthKey = () => {
  return localStorage.getItem(BASIC_AUTH_KEY)
}

export const setBasicAuthKey = (basic_auth_key) => {
  localStorage.setItem(BASIC_AUTH_KEY, basic_auth_key)
}

export const removeBasicAuthKey = () => {
  localStorage.removeItem(BASIC_AUTH_KEY)
}

export const getBaseApiUrl = () => {
  if (process.env.NODE_ENV === 'development') {
    return BASE_API_URL_LOCAL
  }
  return BASE_API_URL
}
